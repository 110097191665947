<template>
    <ul class='all_white new_info'>
        <li class="new_info_item" @click="goDetail(item.id)" v-for="(item, index) in list" :key="index">
            <img :src="imgList[index]" alt="">
            <p>{{item.fullTitle}}</p>
        </li>
    </ul>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
import { newsList, isValid } from '../../utils/api'
import { jumpTo } from '../../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             list: [
             ],
             imgList: []
        })
        async function loadData() {
            try {
                const result = await newsList({gid: 207, size: 10})
                if (isValid(result)) {
                    result.data.forEach(ele => {
                        state.list.push({fullTitle: ele.fullTitle, id: ele.id})
                    })
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        function goDetail(id) {
            jumpTo(`/newDetail?id=${id}`)
        }
        return {
            ...toRefs(state),
            goDetail
        }
    },
}
</script>
<style lang='scss' scoped>
.new_info {
    padding: $margin-big;
    &_item {
        background: $bg-gray;
        margin-bottom: 20px;
        border-radius: $box-radius-small;
        img {
            max-width: 100%;
        }
        p {
            padding: 10px;
            width: 90%;
            overflow: hidden;  /*超出部分隐藏*/
            white-space: nowrap;  /*禁止换行*/
            text-overflow: ellipsis; /*省略号*/
            
        }
    }
}
</style>